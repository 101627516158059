<template>
  <div class="simple-editor">
    <div class="editor-node detail" ref="editorNode"></div>
  </div>
</template>

<script>
// import apiUrl from '@/variables/config.js';
import {apiFileUpload} from '@/utils/axios/axiosInstance.js';


import Quill from 'quill';
import 'quill/dist/quill.snow.css';

import ImageUploader from "quill-image-uploader";
import ImageResize from 'quill-image-resize-alt-module';
import htmlEditButton from "quill-html-edit-button";

Quill.register("modules/imageUploader", ImageUploader);
Quill.register('modules/imageResize', ImageResize);
Quill.register("modules/htmlEditButton", htmlEditButton);


export default {
  name: 'TextEditor',
  props: {
    value: {
      default: '',
      type: String
    },
    uploaderKey: {
      default: '',
      type: String
    }
  },

  data () {
    return {
      editorContent: null,
      editorInstance: null,
      editorOpts: {
        modules: {
          toolbar: [
            [{ 'header': [2, 3, 4, 5, 6, false] }], // 1, 2, 
            // [{ 'font': [] }],
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote'], //, 'code-block'
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'align':''}, { 'align':'center'}, { 'align':'right'}, { 'align':'justify'}],
            [{ 'color': [] }, { 'background': [] }],
            ['clean'],
            ['link', 'image', 'video'],
            // [{ 'direction': 'rtl' }]
          ],
          clipboard: {
            matchVisual: false // use to remove unneeded paragraph after save
          },
          imageUploader: {
              upload: file => {
                return new Promise((resolve, reject) => {
                  let formData = new FormData();
                  formData.append('file'        , file);
                  formData.append("section"     , 'content');
                  formData.append("parent_id"   , this.uploaderKey);
                  formData.append("relate_id"   , '');
                  formData.append("type"        , 'editor');
                  formData.append("is_replace"  , 1);

                  apiFileUpload
                    .post('/file/upload', formData)
                    .then((res) => {
                      console.log(res.data);
                      resolve(res.data.result.url);
                    })
                    .catch((err) => {
                      console.log(err);
                      reject("Upload failed");
                      alert("Upload file error !!!");
                    });
                });
              }
          },
          imageResize: {
            parchment: Quill.import('parchment')
          },
          htmlEditButton: {
            // debug: true, 
            // syntax: true,
            buttonHTML: '<svg viewBox="0 0 18 18"> <polyline class="ql-even ql-stroke" points="5 7 3 9 5 11"></polyline> <polyline class="ql-even ql-stroke" points="13 7 15 9 13 11"></polyline> <line class="ql-stroke" x1="10" x2="8" y1="5" y2="13"></line> </svg>',
          },
        },
        theme: 'snow'
      }
    }
  },

  watch: {
    value (newVal) {
      // Only update the content if it's changed from an external source
      // or else it'll act weird when you try to type anything
      if (newVal !== this.editorContent) {
        this.editorInstance.pasteHTML(newVal)
      }
    }
  },

  mounted () {
    this.initializeEditor()
  },

  beforeDestroy () {
    // Turn off all listeners set on text-change
    this.editorInstance.off('text-change')
  },

  methods: {
    initializeEditor () {
      // Set initial content that's going to be picked up by Quill
      this.$refs.editorNode.innerHTML = this.value

      // Create the Quill instance
      this.editorInstance = new Quill(this.$refs.editorNode, this.editorOpts)

      
      // this.editorInstance.tagName = 'DIV';
      // Quill.register(Block, true);

      // Setup handler for whenever things change inside Quill
      this.editorInstance.on('text-change', this.onEditorContentChange)
      // Save any initial content to this.editorContent
      this.setEditorContent()
    },
    onEditorContentChange () {
      // Whenever we change anything, update this.editorContent
      this.setEditorContent()
      // Then emit its value as input so we have a working v-model
      // This $emit will be catched up in the watch:value
      // that's why we guard against calling pasteHTML
      // calling that function while we are typing is undesirable
      this.$emit('input', this.editorContent)
    },
    setEditorContent () {
      this.editorContent = this.editorInstance.getText().trim()
        ? this.editorInstance.root.innerHTML
        : ''
    }
  }
}
</script>

<style scoped>
.simple-editor {
  float: none;
  margin: auto;
  
  background: #fff;
}
.ql-toolbar.ql-snow button {
  color: #f00 !important;
}
</style>